import React, { useContext, useState } from 'react';

import FolderIcon from 'assets/FolderIcon';
import PropTypes from 'prop-types';

import { ActionDropdown, H3, Icon, ToolTip } from '@driftt/tide-core';

import ModalPortal from 'components/modalPortal';
import FolderContext from 'context/FolderContext';
import { UserContext } from 'context/UserContext';

import DeleteFolderModal from './DeleteFolderModal';
import RenameFolderModal from './RenameFolderModal';

const WarningToolTip = ({ label, warning, showWarning, ...otherProps }) => {
  return showWarning ? (
    <ToolTip {...otherProps} render={() => warning}>
      {label}
    </ToolTip>
  ) : (
    label
  );
};

WarningToolTip.propTypes = {
  label: PropTypes.node,
  warning: PropTypes.string,
  showWarning: PropTypes.bool,
}

function FolderTitle() {
  const { user } = useContext(UserContext);
  const { folder } = useContext(FolderContext);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  if (!folder.parentFolder) {
    return <span>{folder.title}</span>;
  }

  const hasOwnership = folder.owner === user.id;

  return (
    <>
      <ActionDropdown
        className="folder-title-action-dropdown"
        trigger={
          <H3 style={{ marginTop: 0, cursor: 'pointer' }}>
            <FolderIcon /> {folder.title} <Icon size="small" name="chevron-down" width="8" />
          </H3>
        }
        align={folder.title.length > 6 ? 'right' : 'left'}
        actions={[
          {
            label: (
              <WarningToolTip
                label="Rename folder"
                warning="You don’t have permission to rename this folder"
                showWarning={!hasOwnership}
                flow="right"
              />
            ),
            action: (hideDropdown) => {
              setShowRenameModal(true);
              hideDropdown();
            },
            disabled: !hasOwnership,
          },
          {
            label: (
              <WarningToolTip
                label="Delete folder"
                warning="You don’t have permission to delete this folder"
                showWarning={!hasOwnership}
                flow="right"
              />
            ),
            action: (hideDropdown) => {
              setShowDeleteModal(true);
              hideDropdown();
            },
            disabled: !hasOwnership,
          },
        ]}
      />
      <ModalPortal>
        <RenameFolderModal
          folderId={folder.id}
          folderTitle={folder.title}
          open={showRenameModal}
          onClose={() => setShowRenameModal(false)}
        />
        <DeleteFolderModal
          folderId={folder.id}
          parentFolder={folder.parentFolder}
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
      </ModalPortal>
    </>
  );
}

export default FolderTitle;
