import * as React from 'react';
import { useState } from 'react';

import { Icon, Input, Layout } from '@driftt/tide-core';

import './style.css';

interface InteractiveCaptionsProps {
  videoTracks: { cues_: any }[];
  selectionDelegate: (track: string) => void;
}

const InteractiveCaptions = ({ videoTracks, selectionDelegate }: InteractiveCaptionsProps) => {
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  /**
   * BKDR Hash
   *
   * @param {String} str string to hash
   * @returns {Number}
   */
  const BKDRHash = (str) => {
    const seed = 131;
    const seed2 = 137;
    let hash = 0;
    // make hash more sensitive for short string like 'a', 'b', 'c'
    str += 'x';
    // Note: Number.MAX_SAFE_INTEGER equals 9007199254740991
    const MAX_SAFE_INTEGER = parseInt((9007199254740991 / seed2).toString(), 10);
    for (let i = 0; i < str.length; i++) {
      if (hash > MAX_SAFE_INTEGER) {
        hash = parseInt((hash / seed2).toString(), 10);
      }
      hash = hash + seed + str.charCodeAt(i);
    }
    return hash;
  };

  const BASE_STYLES = [
    {
      background: 'var(--color-s5-8)',
    },
    {
      background: 'var(--color-p3-8)',
    },
    {
      background: 'var(--color-s3-9)',
    },
    {
      background: 'var(--color-s4-7)',
    },
    {
      background: 'var(--color-s2-8)',
    },
    {
      background: 'var(--color-s1-9)',
    },
  ];

  const getBackground = (hashInput) => {
    const baseColorHashOutput = hashInput
      ? BKDRHash(hashInput)
      : Math.floor(Math.random() * BASE_STYLES.length);
    return BASE_STYLES[baseColorHashOutput % BASE_STYLES.length].background;
  };

  const secondsToTime = (timeInSeconds) => {
    const hour = Math.floor(timeInSeconds / 3600);
    let min: number | string = Math.floor((timeInSeconds % 3600) / 60);
    let sec: number | string = Math.floor(timeInSeconds % 60);
    sec = sec < 10 ? '0' + sec : sec;
    min = hour > 0 && min < 10 ? '0' + min : min;
    if (hour > 0) {
      return hour + ':' + min + ':' + sec;
    }
    return min + ':' + sec;
  };

  const getSpeakerAndText = (cue) => {
    const originalText = cue.text;
    const startTime = cue.startTime;
    let speaker;
    let text = originalText;
    if (cue.text.includes(':')) {
      //zoom transcripts have : as speaker delimiter
      const speakerEnd = cue.text.indexOf(':');
      speaker = originalText.substring(0, speakerEnd);
      text = originalText.substring(speakerEnd + 1, originalText.length - 1);
    } else if (cue.text.includes('<v ')) {
      const speakerEnd = cue.text.indexOf('>');
      speaker = originalText.substring(3, speakerEnd);
      text = originalText.substring(speakerEnd + 1, cue.text.indexOf('</v>'));
    }
    return {
      startTime,
      speaker,
      text,
      background: getBackground(speaker),
    };
  };

  const createLine = function (cueInfo) {
    return (
      <div className="cue-line" onClick={() => selectionDelegate(cueInfo.startTime)}>
        <div className="cue-info">
          {cueInfo.speaker && <span className="cue-speaker">{cueInfo.speaker}</span>}
          <span className="cue-timestamp">{secondsToTime(cueInfo.startTime)}</span>
        </div>
        <span className="cue-speaker-identifier" style={{ background: cueInfo.background }} />

        <span className="cue-text">{cueInfo.text}</span>
      </div>
    );
  };

  const cuesReducer = function (result, currentValue) {
    if (!!searchTerm && !currentValue.text.includes(searchTerm)) {
      return result;
    }
    const cueInfo = getSpeakerAndText(currentValue);

    if (!cueInfo.speaker) {
      const previousCue = result[result.length - 1];
      if (previousCue) {
        cueInfo.speaker = previousCue.speaker;
      }
    }
    result.push(cueInfo);
    return result;
  };

  return (
    <Layout className="video-interactive-transcripts">
      <div className="transcript-header">
        <h3 className="transcript-header-content">Transcripts</h3>
        <div className="transcript-header-icon" onClick={() => setExpanded(!expanded)}>
          <Icon name={expanded ? 'chevron-down' : 'chevron-up'} width={12} />
        </div>
      </div>
      {expanded && (
        <Input
          className="transcript-search"
          type="search"
          placeholder="Search call"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
      {expanded && (
        <Layout className="transcript-body">
          {videoTracks.map((track) =>
            track.cues_.reduce(cuesReducer, []).map((cueInfo) => {
              return createLine(cueInfo);
            }),
          )}
        </Layout>
      )}
    </Layout>
  );
};

export default InteractiveCaptions;
