import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button,Footer, Input, Modal } from '@driftt/tide-core';

import EmailAndDomainListEditor from 'components/emailAndDomainListEditor';

import './styles.css';
class PrivacyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: this.props.editGroup ? this.props.editGroup.groupName : '',
      emailsAndDomainsList: this.props.editGroup ? this.props.editGroup.emailsAndDomainsList : [],
      isGroupNameBlank: false,
      areThereNoEmails: false,
      isGroupNameDuplicated: false,
    };
  }

  updateGroupName = (groupName) => {
    this.setState({
      groupName: groupName.target.value,
      isGroupNameBlank: false,
      isGroupNameDuplicated: false,
    });
  };

  updateEmailList = (currentEmailList) => {
    this.setState({
      emailsAndDomainsList: currentEmailList,
      areThereNoEmails: false,
    });
  };

  onSave = () => {
    const { addNewCustomPrivacy, editGroup, updateGroupCallback, closeModal, groupNamesList } =
      this.props;
    const { groupName, emailsAndDomainsList } = this.state;
    if (groupName === '') {
      this.setState({ isGroupNameBlank: true });
    } else if (
      groupNamesList.includes(groupName) &&
      (editGroup === null || editGroup.groupName !== groupName)
    ) {
      this.setState({ isGroupNameDuplicated: true });
    } else if (emailsAndDomainsList.length === 0) {
      this.setState({ areThereNoEmails: true });
    } else if (editGroup === null) {
      addNewCustomPrivacy(groupName, emailsAndDomainsList);
      this.setState({
        groupName: '',
        emailsAndDomainsList: [],
        isGroupNameBlank: false,
        areThereNoEmails: false,
        isGroupNameDuplicated: false,
      });
    } else {
      if (
        editGroup.groupName !== groupName ||
        editGroup.emailsAndDomainsList !== emailsAndDomainsList
      ) {
        updateGroupCallback(editGroup.value, groupName, emailsAndDomainsList);
      } else {
        closeModal();
      }
      this.setState({
        isGroupNameBlank: false,
        areThereNoEmails: false,
        isGroupNameDuplicated: false,
      });
    }
  };

  render() {
    const { isOpen, closeModal, editGroup } = this.props;
    const {
      groupName,
      emailsAndDomainsList,
      isGroupNameBlank,
      areThereNoEmails,
      isGroupNameDuplicated,
    } = this.state;
    const isSaveButtonDisabled = groupName === '' && emailsAndDomainsList.length === 0;
    return (
      <Modal
        className="privacy-modal"
        title={editGroup ? `Edit "` + editGroup.groupName + `"` : 'Add a privacy group'}
        open={isOpen}
        onClose={closeModal}
        size={'small'}
      >
        <Input
          className={cx('group-name-input', { error: isGroupNameBlank || isGroupNameDuplicated })}
          label="Group name"
          placeholder='eg. "Senior Leadership"'
          value={groupName}
          onChange={this.updateGroupName}
        ></Input>
        {isGroupNameBlank && (
          <p className="group-name-warning group-error">A name for your group is required</p>
        )}
        {isGroupNameDuplicated && (
          <p className="duplicate-warning group-error">
            You already have a privacy group with this name
          </p>
        )}
        <p className="email-domain-header">People</p>
        <EmailAndDomainListEditor
          emailsAndDomainsList={emailsAndDomainsList}
          onEmailListChange={this.updateEmailList}
          emptyFieldWarning={areThereNoEmails}
        ></EmailAndDomainListEditor>
        {areThereNoEmails && (
          <p className="no-emails-error">At least one email address or domain is required</p>
        )}
        <p className="share-message">
          Share videos with an entire company by entering their domain<br></br>(@companyname.com)
        </p>
        <Footer>
          <Button type="secondary" onClick={this.onSave} disabled={isSaveButtonDisabled}>
            Save
          </Button>
          <Button type="tertiary" onClick={closeModal}>
            Cancel
          </Button>
        </Footer>
      </Modal>
    );
  }
}

PrivacyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  addNewCustomPrivacy: PropTypes.func.isRequired,
  updateGroupCallback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  editGroup: PropTypes.object,
  groupNamesList: PropTypes.array.isRequired,
};

export default PrivacyModal;
