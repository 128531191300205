import React from 'react';

import * as S3 from 'aws-sdk/clients/s3';

import {
  A,
  Button,
  CircleLoader,
  DefaultContainer,
  Div,
  FixedWidthContainer,
  Footer,
  H2,
  Icon,
  LoadStyles,
  P,
} from '@driftt/tide-core';
import { UploadArea } from '@driftt/tide-file-upload';

import { VideoAPI } from 'api';
import { UserContext } from 'context/UserContext';
import { generateShareUrl, openTeamsUpgradePlaybook } from 'utils';
import {
  API_URLS,
  FILE_AND_VIDEO_FORMATS,
  SOURCE_TYPE,
  STREAM_TYPE,
  UPLOAD_SOURCE,
  VIDEO_FILE_NAME_PREFIX,
  VIDEO_PLAY_MODE,
  VIDEO_STATUS,
} from 'utils/constants';

import './styles.css';

LoadStyles();

export default class Upload extends React.Component<any, any> {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      loadingImage: false,
      videoSrc: '',
      videoName: '',
      pendingUpload: null,
      isUploading: false,
      uploadingProgress: 0,
      sizeError: false,
      typeError: false,
      readError: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    window.drift?.hide();
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions
    window.drift?.show();
  }

  saveVideoFile = (newUrl, newName) => this.setState({ videoSrc: newUrl, videoName: newName });
  clearVideoFile = () =>
    this.setState({
      videoSrc: '',
      videoName: '',
      pendingUpload: null,
      sizeError: false,
      typeError: false,
      readError: false,
    });

  updateUploadLoadingProgress = (percentage) => {
    this.setState({ uploadingProgress: percentage });
  };

  createVideo = (params) => {
    return VideoAPI.post(API_URLS.videos.list, {
      title: params.title,
      status: VIDEO_STATUS.DRAFT,
      sourceType: SOURCE_TYPE.VIDEO,
      playMode: VIDEO_PLAY_MODE.SCREEN_ONLY,
      extra_data: {
        upload_source: UPLOAD_SOURCE.USER,
      },
      videoSourceSet: [
        {
          sourceType: SOURCE_TYPE.VIDEO,
          streamType: STREAM_TYPE.SCREEN,
          fileFormat: params.fileFormat,
          videoFormat: params.videoFormat,
        },
      ],
    }).then(({ data }) => {
      const videoData = {
        videoId: data.id,
        videoSourceId: data.videoSourceSet[0].id,
        shareUrl: generateShareUrl(data.shareUrl),
        fileName: '',
      };

      const currentTimeInMillis = new Date().getTime();
      videoData.fileName = `${VIDEO_FILE_NAME_PREFIX}${videoData.videoId}-${
        videoData.videoSourceId
      }-${currentTimeInMillis}${params.fileNameSuffix + params.fileExtension}`;
      return videoData;
    });
  };

  getUploadInfo = (videoData) => {
    return VideoAPI.get(API_URLS.videos.uploadInfo(videoData.videoId), {
      params: {
        video_file_name: videoData.fileName,
      },
    });
  };

  uploadVideo = (file, videoData, uploadInfo) => {
    const url = API_URLS.videos.uploadToken(videoData.videoId);
    const urlWithKey = `${url}?key=${btoa(uploadInfo.videoKey)}`;

    const boundUpdateProcessFunc = this.updateUploadLoadingProgress.bind(this);

    return VideoAPI.get(urlWithKey).then(({ data }) => {
      const s3UploadPromise = new Promise((resolve, reject) => {
        const fileKey = uploadInfo.videoKey;
        let reader = new FileReader();
        reader.onload = function () {
          const body = reader.result;
          // @ts-ignore
          const s3 = new S3({
            accessKeyId: data.accessKey,
            secretAccessKey: data.secretKey,
            sessionToken: data.sessionToken,
            correctClockSkew: true,
            httpOptions: {
              timeout: 3600000, // 60 minutes
            },
          });

          const s3UploadRequest = s3.upload(
            {
              Bucket: uploadInfo.bucket,
              Key: fileKey,
              Body: body,
              ServerSideEncryption: 'AES256',
            },
            {
              partSize: 5 * 1024 * 1024,
              queueSize: 4,
              leavePartsOnError: true,
            },
          );

          s3UploadRequest.on('httpUploadProgress', (progress) => {
            const currentProgress = Math.round(0 + (100 * progress.loaded) / progress.total);
            boundUpdateProcessFunc(currentProgress);
          });

          s3UploadRequest.send((err) => {
            // @ts-ignore
            reader = null;
            if (err) {
              Error('Could not upload to s3', err);
              reject(err);
            } else {
              resolve('success');
            }
          });
        };
        reader.readAsArrayBuffer(file);
      });

      return s3UploadPromise;
    });
  };

  updateVideoSource = (videoData) => {
    return VideoAPI.patch(API_URLS.videoSources.update(videoData.videoSourceId), {
      file: videoData.fileName,
    });
  };

  updateVideoStatus = (videoData) => {
    return VideoAPI.patch(API_URLS.videos.update(videoData.videoId), {
      status: VIDEO_STATUS.UPLOADED,
    });
  };

  createPendingUpload = (file) => {
    const { onSuccess = this.handleSuccessfulUpload } = this.props;

    const params = {
      title: file.name,
      fileFormat: FILE_AND_VIDEO_FORMATS[file.type].fileType,
      videoFormat: FILE_AND_VIDEO_FORMATS[file.type].videoType,
      fileNameSuffix: '-uploaded',
      fileExtension: FILE_AND_VIDEO_FORMATS[file.type].audioMuxedType,
    };

    this.setState({
      pendingUpload: () =>
        this.createVideo(params)
          .then((videoData) => {
            return this.getUploadInfo(videoData)
              .then(({ data }) => this.uploadVideo(file, videoData, data))
              .then(() => this.updateVideoSource(videoData))
              .then(() => this.updateVideoStatus(videoData))
              .then(() => onSuccess(videoData));
          })
          .catch((err) => {
            Error('Could not upload video', err);
          }),
    });
  };

  handleVideoUploadFile = (file) => {
    // have to return a promise that then returns { downloadUrl: file.preview }
    // have to create a pending upload call that then gets called via the upload button
    this.setState({
      readError: false,
      typeError: false,
      sizeError: false,
    });
    return new Promise((resolve) => {
      this.createPendingUpload(file);
      return resolve({ downloadUrl: file.preview });
    });
  };

  triggerUpload = () => {
    this.setState({
      isUploading: true,
    });
    // @ts-ignore
    this.state.pendingUpload();
  };

  backToLibrary = () => {
    window.location.replace('/video');
  };

  handleSuccessfulUpload = (videoData) => {
    window.location.replace(videoData.shareUrl);
  };

  render() {
    const { isFreeVideoUser } = this.context;
    const {
      videoSrc,
      videoName,
      isUploading,
      uploadingProgress,
      pendingUpload,
      readError,
      typeError,
      sizeError,
    } = this.state;
    const { handleCancel = this.backToLibrary, renderAsModal = false } = this.props;
    const has_video_upload = !isFreeVideoUser();

    const UploadComponent = () => (
      <Div className="file-drop-component">
        <FixedWidthContainer className="file-drop-container">
          {!renderAsModal && <H2 className="file-drop-header">Upload a video to share</H2>}
          <UploadArea
            type="video"
            onReadError={(e) => this.setState({ readError: true })}
            onTypeError={() => this.setState({ typeError: true })}
            onSizeError={() => this.setState({ sizeError: true })}
            handleSaveFile={this.saveVideoFile}
            handleClearFile={this.clearVideoFile}
            handleUploadFile={this.handleVideoUploadFile}
            previewUrl={videoSrc}
            previewName={videoName}
            disabled={!has_video_upload}
            showUpgrade={!has_video_upload}
          />
          {has_video_upload ? (
            <P className="file-drop-helper-text">
              <span role="img" aria-label="hand-emoji">
                👉
              </span>{' '}
              Tip: For best results, the uploaded video should be a .mov or .mp4 file.
            </P>
          ) : (
            <P className="file-drop-helper-text">
              <A onClick={openTeamsUpgradePlaybook}>Upgrade to unload unlimited videos</A>
            </P>
          )}
          {readError && (
            <P className="file-drop-helper-text error">
              Oops! Something went wrong. Please try again.
            </P>
          )}
          {typeError && (
            <P className="file-drop-helper-text error">
              This is not a supported file type. Try uploading a .mov or .mp4 file instead.
            </P>
          )}
          {sizeError && (
            <P className="file-drop-helper-text error">
              The file you’re trying to upload exceeds the size limit of 1 GB.
            </P>
          )}
        </FixedWidthContainer>
      </Div>
    );

    if (renderAsModal) {
      return (
        <DefaultContainer className="video-upload-container">
          {isUploading ? (
            <FixedWidthContainer className="loading-container">
              <CircleLoader size="large" />
              <P className="upload-text">Uploading - {uploadingProgress}%</P>
            </FixedWidthContainer>
          ) : (
            <UploadComponent />
          )}
          <Footer>
            <Button disabled={!pendingUpload} onClick={this.triggerUpload}>
              Upload
            </Button>
            <Button type="tertiary" onClick={handleCancel}>
              Cancel
            </Button>
          </Footer>
        </DefaultContainer>
      );
    }

    return (
      <DefaultContainer className="video-upload-container video-upload-page">
        {/*@ts-ignore*/}
        <Div className="close" onClick={() => this.backToLibrary()}>
          <Icon name="close" height={16} width={16} />
        </Div>
        {isUploading ? (
          <FixedWidthContainer className="loading-container">
            <CircleLoader size="large" />
            <P className="upload-text">Uploading - {uploadingProgress}%</P>
          </FixedWidthContainer>
        ) : (
          <>
            <UploadComponent />
            <Div className="button-container">
              <Button type="tertiary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button disabled={!pendingUpload} onClick={this.triggerUpload}>
                Upload
              </Button>
            </Div>
          </>
        )}
      </DefaultContainer>
    );
  }
}
