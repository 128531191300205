import * as React from 'react';
import { useHistory } from 'react-router-dom';

import * as PropTypes from 'prop-types';

import { Button, Footer, Modal, P } from '@driftt/tide-core';

import FolderContext from 'context/FolderContext';
import { findFolderById, getFolderRelativeUrl } from 'utils/folderUtils';

export interface DeleteFolderModalProps {
  folderId: number;
  parentFolder: number;
  open: boolean;
  onClose: () => void;
}

const DeleteFolderModal: React.FC<DeleteFolderModalProps> = ({
  folderId,
  parentFolder,
  open,
  onClose,
}) => {
  const history = useHistory();
  const { folders, onDeleteFolder } = React.useContext(FolderContext);

  const handleConfirmDeleteFolder = () => {
    //@ts-ignore
    onDeleteFolder({ id: folderId });
    // Re-route to this folder's parent when it's deleted.
    //@ts-ignore
    const folder = findFolderById(parentFolder, folders);
    history.push(folder ? getFolderRelativeUrl(folder) : '/video');
  };

  return (
    <Modal
      className="delete-folder-modal"
      title="Delete folder"
      size="small"
      open={open}
      onClose={onClose}
      type="warning"
    >
      <P>Are you sure? This folder and all its data will be gone forever.</P>
      <Footer>
        <Button
          type="secondary"
          onClick={() => {
            handleConfirmDeleteFolder();
            onClose();
          }}
        >
          Confirm
        </Button>
        <Button type="tertiary" onClick={onClose}>
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
};

DeleteFolderModal.propTypes = {
  folderId: PropTypes.number.isRequired,
  parentFolder: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteFolderModal;
