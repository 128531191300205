import React from 'react'

function FolderIcon() {
  return (
    <svg
      className="folder-icon"
      width="16"
      height="13"
      viewBox="0 0 25 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={[
          'M23.4004 19.5994H1.75969C1.09685 19.5994 0.557434 19.0609 0.557434 ',
          '18.3992V1.6004C0.557434 0.93875 1.09685 0.400604 1.75969 ',
          '0.400604H9.4806C9.49663 0.400604 9.51065 0.407822 9.52628 ',
          '0.409827C9.55514 0.413035 9.58239 0.417045 9.61004 0.426669C9.63569 ',
          '0.435491 9.65773 0.448324 9.68057 0.461958C9.6954 0.470379 9.71223 ',
          '0.473587 9.72586 0.484414L12.7167 2.8002H23.4004C24.0632 2.8002 ',
          '24.6026 3.33875 24.6026 4.0004V18.3992C24.6026 19.0609 24.0632 ',
          '19.5994 23.4004 19.5994Z',
        ].join('')}
        fill="white"
        stroke="#353F45"
      />
    </svg>
  )
}

export default FolderIcon
