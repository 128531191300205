import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

export function Portal({ children, to }) {
  if (to) {
    const ele = document.querySelector(to);
    if (ele) {
      return ReactDOM.createPortal(children, ele);
    }
  }
  return ReactDOM.createPortal(children, document.body);
}

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  to: PropTypes.string,
};
