import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import './SelectionBlock.css';

function SelectionBlock({ as = 'div', className, selected, children }) {
  const AsComponent = as;

  return (
    <AsComponent
      className={cx('selection-block', { 'selection-block--selected': selected }, className)}
    >
      {children}
    </AsComponent>
  );
}

SelectionBlock.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  selected: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default SelectionBlock;
