import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

function ModalPortal({ children }) {
  const el = useRef(document.createElement('div'));
  const root = useRef(document.getElementById('modal-root'));

  if (!root.current) {
    throw new Error('ModalPortal needs a #modal-root element to render into.');
  }

  useEffect(() => {
    const _el = el.current;
    const _root = root.current;
    _root.appendChild(_el);
    return () => _root.removeChild(_el);
  }, []);

  return ReactDOM.createPortal(children, el.current);
}

ModalPortal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ModalPortal;
