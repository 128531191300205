import * as React from 'react';

import cx from 'classnames';

import { CircleLoader } from '@driftt/tide-core';

import './styles.css';

const { useRef } = React;

const FullPageSpinner = ({
  show,
  hideBlur = false,
  colorMode = 'light',
}: {
  show: boolean;
  hideBlur: boolean;
  colorMode: string;
}) => {
  const div = useRef(null);
  // @ts-ignore
  const handleBlur = () => div.current && div.current.focus();

  if (!show) {
    return null;
  }

  return (
    <div
      className={cx('full-page-spinner-overlay', { 'hide-spinner-blur': hideBlur })}
      ref={div}
      tabIndex={0}
      onBlur={handleBlur}>
      <CircleLoader size="large" colorMode={colorMode} />
    </div>
  );
};

export default FullPageSpinner;
