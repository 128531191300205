import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs } from '@driftt/tide-core';

import FolderContext from 'context/FolderContext';
import { findFolderById, getFolderPath, getFolderRelativeUrl } from 'utils/folderUtils';

function FolderBreadcrumbs() {
  const history = useHistory();
  const { folder, folders } = React.useContext(FolderContext);

  const headings = getFolderPath(folder, folders as any)
    .map((id) => findFolderById(id, folders as any))
    .map((folder) => ({
      title: folder ? folder.title : '<unknown folder>',
      link: folder ? getFolderRelativeUrl(folder) : '/video',
    }));
  return headings.length > 0 ? (
    <Breadcrumbs
      headings={headings}
      onHeadingClick={({ link }, event) => {
        event.preventDefault();
        history.push(link);
      }}
    />
  ) : null;
}

export default FolderBreadcrumbs;
