import * as React from 'react';

import { ReactComponent as Arrow } from 'assets/svg/back-arrow.svg';

import { Div,H6 } from '@driftt/tide-core';

import './style.css';

interface IBackArrow {
  onClick: () => void;
}

const BackArrow = ({ onClick }: IBackArrow) => (
  <Div className="back-arrow-container" onClick={onClick}>
    <Arrow />
    <H6 className="back-arrow-text">Back</H6>
  </Div>
);

export default BackArrow;
