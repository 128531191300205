import * as React from 'react';
import { useEffect, useState } from 'react';

// @ts-ignore
import recordVideTooltipPlus from 'assets/images/recordVideoTooltipPlus.png'

import { Button, Div } from '@driftt/tide-core';
import { FeatureTooltip } from '@driftt/tide-tooltips';

import MobileTooltipQR from 'components/mobileTooltipQR';
import { EXTENSION_URL } from 'utils/constants';
import {
  checkForExtension,
  checkForNewExtension,
  openExtensionRecordingModal,
} from 'utils/extensionUtils';

import './RecordVideoButton.css';

const RecordVideoButton = () => {
  const [showToolip, setShowTooltip] = useState<boolean>(false);
  const [whichExtension, setWhichExtension] = useState<'old' | 'new' | null>(null);

  useEffect(() => {
    checkForExtension().then((result) => {
      if (result && !whichExtension) setWhichExtension('old');
    });
    checkForNewExtension().then((result) => {
      if (result) setWhichExtension('new');
    });
  }, [whichExtension]);

  const dispatchOpenRecordingModalMessage = () => {
    // @ts-ignore
    openExtensionRecordingModal(whichExtension);
  };

  return (
    <>
      <FeatureTooltip
        className="record-video-education"
        showTooltip={showToolip}
        onClose={() => setShowTooltip(false)}
        tooltipSide="bottom"
        tooltipCaretLocation="right"
        headerCopy="Get started with Drift Video"
        bodyCopy="Make sure you're in a Chrome browser and have Drift Video to get started recording."
        mediaComponent={
          <img src={recordVideTooltipPlus} alt="record-media-tooltip" />
        }
        buttonInfoArray={[
          {
            copy: 'Get Drift Video',
            href: EXTENSION_URL,
          },
        ]}
      >
        <Div className="record-video-education-anchor"></Div>
      </FeatureTooltip>
      <MobileTooltipQR>
        <Button
          className="record-video"
          type="primary"
          onClick={() =>
            whichExtension ? dispatchOpenRecordingModalMessage() : setShowTooltip(true)
          }
        >
          Record video
        </Button>
      </MobileTooltipQR>
    </>
  );
};

export default RecordVideoButton;
