import React, { useRef, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button, Div } from '@driftt/tide-core';
import { Icon } from '@driftt/tide-icons';

import PortalTooltip from './PortalTooltip';

import './styles.css';

const QR_TOOLTIP_STORE_NAME = 'mobile.education.qr.activityTimeline';

export const isDateOutsideRange = (date, timesSeen) => {
  if (!timesSeen) return true;
  if (!date) return false;

  const monthRange = timesSeen > 3 ? 3 : 1;

  return moment().diff(date, 'months') > monthRange;
};

function TooltipContent({ onClose, isVisible }) {
  return (
    <div className={isVisible ? 'Mobile-TooltipCard' : 'Mobile-HiddenCard'}>
      <Div className={'Mobile-RightArrow'} />
      <Div className={'Mobile-CloseIcon'} onClick={onClose}>
        <Icon name="close" width={12} height={12} />
      </Div>

      <Div className={'Mobile-TooltipColumns'}>
        <Div className={'Mobile-TooltipText'}>
          <Div className={'Mobile-Title'}>
            Record and share videos on the go
            <span role="img" aria-label="iphone">
              📱
            </span>
          </Div>
          <Div className={'Mobile-Body'}>
            Download Drift Mobile now to keep conversations going wherever you are.
          </Div>
          <Button onClick={onClose} className={'Mobile-CloseButton'} type="tertiary">
            Close
          </Button>
        </Div>

        <img
          className={'Mobile-TooltipImg'}
          alt=""
          src={'https://s3.amazonaws.com/driftt-email-assets/MobileQR.png'}
          loading="lazy"
        />
      </Div>
    </div>
  );
}

TooltipContent.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
};


function MobileTooltipQR({ children, className }) {
  const [isOpen, setIsOpen] = useState(true);
  const toolTipRef = useRef(null);
  const otherTooltipVisible = document.getElementById('eduTooltip');

  const tooltipData = localStorage.getItem(QR_TOOLTIP_STORE_NAME);
  const { timesSeen, lastSeen } = tooltipData
    ? JSON.parse(tooltipData)
    : { lastSeen: null, timesSeen: 0 };
  const hasTooltip = isDateOutsideRange(lastSeen, timesSeen);

  const onClose = () => {
    localStorage.setItem(
      QR_TOOLTIP_STORE_NAME,
      JSON.stringify({
        lastSeen: new Date().getTime(),
        timesSeen: timesSeen + 1,
        pageSeen: 'VIDEO',
      }),
    );
    setIsOpen(false);
  };

  if (!hasTooltip || !isOpen) return children;

  return (
    <PortalTooltip
      isAlwaysOpen={true}
      className={cx('Mobile-TooltipContainer', className)}
      boundaryRef={toolTipRef.current}
      refElement={toolTipRef.current}
      placement="right"
      tooltipBody={<TooltipContent isVisible={!otherTooltipVisible} onClose={onClose} />}
    >
      <span className={'Mobile-TooltipContentContainer'} ref={toolTipRef}>
        {children}
      </span>
    </PortalTooltip>
  );
}

MobileTooltipQR.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

export default MobileTooltipQR;
