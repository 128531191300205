import React, { useContext,useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Div, Modal,NavigationHeader } from '@driftt/tide-core';

import ModalPortal from 'components/modalPortal';
import UploadApp from 'components/upload/UploadApp';
import FolderContext from 'context/FolderContext';
import useToastNotifications from 'hooks/useToastNotifications';
import { SOURCE_TYPE_GIF, SOURCE_TYPE_MEETING_RECORDING,SOURCE_TYPE_VIDEO } from 'utils/constants';

import CreateFolderButton from './CreateFolderButton';
import FolderBreadcrumbs from './FolderBreadcrumbs';
import FolderTitle from './FolderTitle';
import RecordVideoButton from './RecordVideoButton';

import './styles.css';

function LibraryNavigationHeader({ sourceType, team, refreshTable }) {
  const { folder, onCreateFolder } = useContext(FolderContext);
  const { toast, ToastContainer } = useToastNotifications();
  const [showUploadModal, setShowUploadModal] = useState(false);

  let title = team ? 'Team ' : 'My ';

  switch (sourceType) {
    case SOURCE_TYPE_VIDEO:
      title += 'videos';
      break;
    case SOURCE_TYPE_GIF:
      title += 'GIFs';
      break;
    case SOURCE_TYPE_MEETING_RECORDING:
      title += 'Zoom recordings';
      break;
    default:
      title += 'recordings';
      break;
  }

  if (folder) {
    title = <FolderTitle key={folder.id} folderId={folder.id} folderTitle={folder.title} />;
  }

  const uploadSuccessHandler = () => {
    setShowUploadModal(false);
    toast.success(`Video successfully uploaded.`);

    refreshTable();
  };

  return (
    <>
      <div className="folder-breadcrumbs-container">
        <FolderBreadcrumbs />
      </div>
      <NavigationHeader title={title}>
        <Div className="title-and-upload">
          <CreateFolderButton onCreateFolder={onCreateFolder} />
          <Button
            className="upload-video"
            type="utility"
            icon="upload-2"
            onClick={() => setShowUploadModal(true)}
          >
            Upload video
          </Button>
          <RecordVideoButton />
        </Div>
      </NavigationHeader>
      <ModalPortal>
        <Modal
          className="upload-video-modal"
          title="Upload a video"
          size="small"
          open={showUploadModal}
          onClose={() => setShowUploadModal(false)}
        >
          <UploadApp
            renderAsModal={true}
            handleCancel={() => setShowUploadModal(false)}
            onSuccess={uploadSuccessHandler}
          />
        </Modal>
      </ModalPortal>
      <ToastContainer className="video-library__toast-container" />
    </>
  );
}

LibraryNavigationHeader.propTypes = {
  sourceType: PropTypes.oneOf([SOURCE_TYPE_VIDEO, SOURCE_TYPE_GIF, SOURCE_TYPE_MEETING_RECORDING]),
  team: PropTypes.bool.isRequired,
  refreshTable: PropTypes.func,
};

export default LibraryNavigationHeader;
