import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Footer, Input, Modal } from '@driftt/tide-core';

import FolderContext from 'context/FolderContext';

function RenameFolderModal({ folderId, folderTitle, open, onClose }) {
  const { onRenameFolder } = useContext(FolderContext);
  const [renameTitle, setRenameTitle] = useState(folderTitle);

  const handleConfirmClick = () => {
    onRenameFolder({ id: folderId, title: renameTitle });
    onClose();
  };

  const handleCancelClick = () => {
    setRenameTitle(folderTitle);
    onClose();
  };

  return (
    <Modal
      className="rename-folder-modal"
      title="Rename folder"
      size="small"
      open={open}
      onClose={handleCancelClick}
    >
      <form onSubmit={handleConfirmClick}>
        <Input
          autoFocus
          label="Folder name"
          type="text"
          placeholder="Enter a name..."
          value={renameTitle}
          onChange={(e) => setRenameTitle(e.target.value)}
        />
        <Footer>
          <Button type="primary" disabled={!renameTitle} onClick={handleConfirmClick}>
            Confirm
          </Button>
          <Button type="tertiary" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Footer>
      </form>
    </Modal>
  );
}

RenameFolderModal.propTypes = {
  folderId: PropTypes.number.isRequired,
  folderTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RenameFolderModal;
