import React, { Fragment,useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Icon } from '@driftt/tide-core';

import { fetchEducationContent, markAsComplete } from 'api';
import InstallExtensionCTA from 'components/installExtensionCTA';
import { UserContext } from 'context/UserContext';
import { CHAT_APP_URL, popUp } from 'utils';
import { segmentTrack } from 'utils/metrics';

import './style.css';

function UserNotice({ location }) {
  const { userHasFeature, createUrlPath } = useContext(UserContext);
  const [isLoadingEducationContent, setIsLoadingEducationContent] = useState(true);
  const [educationContent, setEducationContent] = useState([]);
  const [isDismissed, setIsDismissed] = useState(false);
  const HAS_NOTIFICATION_BANNER = userHasFeature('HAS_NOTIFICATION_BANNER');

  useEffect(() => {
    fetchEducationContent(location)
      .then(({ data }) => {
        setEducationContent(data);
      })
      .catch((err) => Error(err));
    setIsLoadingEducationContent(false);
  }, [location]);

  useEffect(() => {
    if (HAS_NOTIFICATION_BANNER && educationContent.length > 0) {
      segmentTrack('User Notice Viewed', { notice: educationContent[0].name });
    }
  }, [educationContent, HAS_NOTIFICATION_BANNER]);

  function dismissUserNotice(shouldCascade) {
    const completionPayload = { id: educationContent[0].id };

    if (shouldCascade) {
      completionPayload.cascade_name = educationContent[0].name;
    }

    return markAsComplete(completionPayload).then(() => setIsDismissed(true));
  }

  function handlePrimaryActionClick() {
    segmentTrack('User Notice CTA Clicked', { type: 'primary', notice: educationContent[0].name });

    popUp({
      path: `${CHAT_APP_URL}/video/settings/notifications`,
      windowName: 'Drift_Video_configure_browser_notifications',
      onClose: () => dismissUserNotice(true),
    });
  }

  function handleSecondaryActionClick() {
    segmentTrack('User Notice CTA Clicked', {
      type: 'secondary',
      notice: educationContent[0].name,
    });
    dismissUserNotice().then(() => (window.location = createUrlPath('/settings2/notifications')));
  }

  function handleDismissClick() {
    segmentTrack('User Notice CTA Clicked', {
      type: 'dismissed',
      notice: educationContent[0].name,
    });
    dismissUserNotice();
  }

  // Wait to attempt extension banner until education states have been checked
  if ((educationContent.length === 0 && isLoadingEducationContent) || isDismissed) {
    return null;
  }

  if (educationContent.length === 0 && !isLoadingEducationContent) {
    return <InstallExtensionCTA />;
  }

  return (
    <Fragment>
      {HAS_NOTIFICATION_BANNER && educationContent[0].name === 'no_channels_notified_alert' && (
        <div className="user-notice-container">
          Drift needs your permission to
          <Button
            className="user-notice-CTA user-notice-primary-CTA"
            type="tertiary"
            size="small"
            onClick={handlePrimaryActionClick}
          >
            <Icon name="notification" width="10" />
            Enable video notifications
          </Button>
          or
          <Button
            className="user-notice-CTA"
            type="tertiary"
            size="small"
            onClick={handleSecondaryActionClick}
          >
            Edit your notification settings
          </Button>
          <div className="user-notice-dismiss" onClick={handleDismissClick}>
            <Icon name="close" width="12" />
          </div>
        </div>
      )}
    </Fragment>
  );
}

UserNotice.propTypes = {
  location: PropTypes.string,
};

export default UserNotice;
