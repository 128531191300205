import React from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs } from '@driftt/tide-core';

import './pageBreadcrumbs.css';

function PageBreadcrumbs() {
  const history = useHistory();

  return (
    <Breadcrumbs
      className="share-page-breadcrumbs"
      onHeadingClick={({ link }, event) => {
        event.preventDefault();
        history.push(link);
      }}
      headings={[
        {
          title: 'Library',
          link: '/video/',
        },
        {
          title: 'Edit & share',
          link: '#',
          isActive: true,
        },
      ]}
    />
  );
}

export default PageBreadcrumbs;
