import React, { useEffect, useState } from 'react';

import { A } from '@driftt/tide-core';

import { EXTENSION_URL } from 'utils/constants';
import { checkForExtension } from 'utils/extensionUtils';

import './InstallExtensionCTA.css';

function InstallExtensionCTA() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    checkForExtension().then((result) => setShow(!result));
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className="install-extension-cta">
      The Drift Video extension isn’t installed, add Drift Video to Chrome to record videos.{' '}
      <A href={EXTENSION_URL} target="_blank" rel="noopener noreferrer">
        Visit Chrome store
      </A>
    </div>
  );
}

export default InstallExtensionCTA;
