import React from 'react';

function TableRowDragPreview({ element }: any) {
  return (
    <div
      className="video-library-table video-library-table--drag-preview"
      style={{ width: window.innerWidth - 375 }}
    >
      <table className="tide-table">
        <tbody>
          <tr>{element}</tr>
        </tbody>
      </table>
    </div>
  );
}

export default TableRowDragPreview;
