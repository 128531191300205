import React, { useCallback } from 'react';
import { useDragLayer } from 'react-dnd';

import TableRowDragPreview from './TableRowDragPreview';

import './styles.css';

function CustomDragLayer() {
  const collectProps = useCallback(
    (monitor) => ({
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
      mouseOffset: monitor.getClientOffset(),
      initialSourceOffset: monitor.getInitialSourceClientOffset(),
    }),
    [],
  );

  const { item, isDragging, mouseOffset, initialSourceOffset } = useDragLayer(collectProps);

  if (!isDragging) {
    return null;
  }

  let style: any = { display: 'none' };

  if (mouseOffset) {
    const transform = `translate(${mouseOffset.x}px, ${mouseOffset.y}px)`;
    // These margins are used in combination with a css animation which drives
    // them to 0. This creates the effect of the dragged item "transporting"
    // from its original position on screen to a new position where its top left
    // corner is lined up with the mouse pointer. This was inspired by Google
    // Drive's drag animation.
    const marginLeft = initialSourceOffset?.x - mouseOffset?.x;
    const marginTop = initialSourceOffset?.y - mouseOffset?.y - 3;

    style = {
      transform,
      WebkitTransform: transform,
      marginLeft,
      marginTop,
    };
  }

  return (
    <div className="custom-drag-layer">
      <div className="custom-drag-layer__positioner" style={style}>
        {Boolean(item.element) && <TableRowDragPreview element={item.element} />}
      </div>
    </div>
  );
}

export default CustomDragLayer;
