import React, { useContext, useState } from 'react';

import { Button, Footer, Input, Modal } from '@driftt/tide-core';

import ModalPortal from 'components/modalPortal';
import FolderContext from 'context/FolderContext';

function CreateFolderButton() {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const { folder, onCreateFolder } = useContext(FolderContext);

  const handleCreateClick = () => {
    onCreateFolder({
      title,
      parentFolder: folder.id,
      privacyStatus: folder.privacyStatus,
    });
    setTitle('');
    setShowModal(false);
  };

  const handleCancelClick = () => {
    setTitle('');
    setShowModal(false);
  };

  return (
    <>
      <Button
        className="new-folder"
        type="utility"
        icon="section"
        onClick={() => setShowModal(true)}
        disabled={folder?.readonly}
      >
        New folder
      </Button>
      <ModalPortal>
        <Modal
          className="new-folder-modal"
          title="New folder"
          size="small"
          open={showModal}
          onClose={handleCancelClick}
        >
          <form onSubmit={handleCreateClick}>
            <Input
              autoFocus
              label="Folder name"
              type="text"
              placeholder="Enter a name..."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Footer>
              <Button type="primary" onClick={handleCreateClick} disabled={!title}>
                Create
              </Button>
              <Button type="tertiary" onClick={handleCancelClick}>
                Cancel
              </Button>
            </Footer>
          </form>
        </Modal>
      </ModalPortal>
    </>
  );
}

export default CreateFolderButton;
