import React from 'react';

import {
  Button,
  CardContainer,
  ColorWay,
  H4,
  Icon,
  Main,
  NavigationHeader,
} from '@driftt/tide-core';

import { openTeamsUpgradePlaybook } from 'utils';

import './styles.css';

function TeamLibraryUpgradeState() {
  return (
    <div className="team-library-upgrade-state">
      <NavigationHeader title="Team Library" />
      <Main>
        <CardContainer padding="large" className="team-library-upgrade-state__card-container">
          <ColorWay type="2">
            <Icon name="cw-video" />
          </ColorWay>
          <H4 className="team-library-upgrade-state__header">
            Upgrade to Teams to share folders, videos and analytics with your coworkers.
          </H4>
          <Button type="tertiary" onClick={openTeamsUpgradePlaybook}>
            Contact us to upgrade
          </Button>
        </CardContainer>
      </Main>
    </div>
  );
}

export default TeamLibraryUpgradeState;
