import React from 'react';

import cx from 'classnames';

import { MultiStringCompact } from '@driftt/tide-core';

import { isValidEmailOrDomain } from 'utils';

import './EmailAndDomainListEditor.css';

interface EmailAndDomainListEditorProps {
  emailsAndDomainsList: string[];
  onEmailListChange: (emailsAndDomainsList: string[]) => void;
  placeholder?: string;
  emptyFieldWarning?: boolean;
}

function EmailAndDomainListEditor({
  emailsAndDomainsList,
  onEmailListChange,
  placeholder = 'Enter email addresses or domains',
  emptyFieldWarning,
}: EmailAndDomainListEditorProps) {
  const changeListValues = (newVals: { values: string[] }) => {
    const currentEmailList = newVals.values;
    onEmailListChange(currentEmailList);
  };

  const validateEmailsAndDomains = (values) => {
    if (values.length === 0) {
      return { errorMsg: false, validValues: values };
    } else {
      const newVal = values[values.length - 1];
      if (!isValidEmailOrDomain(newVal)) {
        return {
          errorMsg: 'This email address or domain is invalid',
          validValues: emailsAndDomainsList,
        };
      } else if (emailsAndDomainsList.indexOf(newVal) !== -1) {
        return {
          errorMsg: 'Duplicate entry',
          validValues: emailsAndDomainsList,
        };
      } else {
        return { errorMsg: false, validValues: values };
      }
    }
  };

  return (
    <div className={cx('email-and-domain-list-editor', { error: emptyFieldWarning })}>
      <MultiStringCompact
        placeholder={placeholder}
        values={emailsAndDomainsList}
        onChange={changeListValues}
        getValidity={validateEmailsAndDomains}
      />
    </div>
  );
}

export default EmailAndDomainListEditor;
