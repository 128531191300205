import React, { useState } from 'react';

import { IconButton } from '@driftt/tide-core';

import './ClonedRecordingBanner.css';

interface CloneRecordingBannerProps {
  initialTitle: string;
}

function ClonedRecordingBanner({ initialTitle }: CloneRecordingBannerProps) {
  const [show, setShow] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <div className="cloned-recording-banner">
      {`You've successfully cloned "${initialTitle}"!`}
      <b className="cloned-recording-banner-notice">
        All chats and notifications received on this video will be sent to your account.
      </b>
      <IconButton
        className="cloned-recording-banner-close"
        icon="close"
        size="small"
        onClick={() => setShow(false)}
      />
    </div>
  );
}

export default ClonedRecordingBanner;
