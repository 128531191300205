import React, { Fragment,useContext, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { A,Button, Footer, Icon, InlineWarning, Input, Modal } from '@driftt/tide-core';

import EmailAndDomainListEditor from 'components/emailAndDomainListEditor';
import SelectionBlock from 'components/selectionBlock';
import { UserContext } from 'context/UserContext';

import './LinkPrivacySelector.css';

const ChatPrivacyWarning = ({ index, isSelected, parentPage, showDisableChatWarning, onClick }) => {
  const tooltipText = ((page) => {
    switch (page) {
      case 'SHARE':
        return 'This setting will disable chat on this video.';
      case 'SETTINGS':
        return 'This setting will disable chat on all newly created videos.';
      default:
        return '';
    }
  })(parentPage);
  return (
    index !== 0 &&
    isSelected &&
    showDisableChatWarning && (
      <div className="disable-chat-warning">
        <InlineWarning type="warning" copy={tooltipText} />
        {parentPage === 'SHARE' && (
          <A className="disable-chat-warning__set-chat" onClick={onClick}>
            Keep chat enabled
          </A>
        )}
      </div>
    )
  );
};

ChatPrivacyWarning.propTypes = {
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  parentPage: PropTypes.string,
  showDisableChatWarning: PropTypes.bool,
  onClick: PropTypes.func,
}

function LinkPrivacySelector({
  className,
  onChange,
  parentPage,
  onDisableChatClick,
  onEditGroupClick,
  deleteCallback,
  onEmailListChange,
  options,
  selectedOption,
}) {
  const { isFreeVideoUser } = useContext(UserContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);
  const isSelected = (value) => value === selectedOption;
  const [showDisableChatWarning, setShowDisableChatWarning] = useState(false);

  const deleteOption = (option) => {
    setIsDeleteModalOpen(false);
    deleteCallback(option);
  };

  const onDeleteGroupClick = (deleteGroup) => {
    if (isFreeVideoUser()) {
      return;
    } else {
      setOptionToDelete(deleteGroup);
      setIsDeleteModalOpen(true);
    }
  };

  const optionsOnChange = (value, e) => {
    setShowDisableChatWarning(true);
    onChange(value, e);
  };

  return (
    <div className={cx('link-privacy-selector', className)}>
      {options.map(({ description, emailList, isGroup, title, value }, index) => (
        <Fragment key={index}>
          <SelectionBlock
            as="label"
            className={cx({
              'link-privacy-selector__label--with-description': Boolean(description),
            })}
            key={index}
            selected={isSelected(value)}
          >
            <Input
              className="link-privacy-selector__radio"
              type="radio"
              name="link-privacy"
              checked={isSelected(value)}
              onChange={(e) => optionsOnChange(value, e)}
            />
            <div className="link-privacy-selector__content">
              <div className="link-privacy-selector__title">{title}</div>
              <div className="link-privacy-selector__description">{description}</div>
              {emailList && isSelected(value) && (
                <div className="link-privacy-selector__email-list">
                  <EmailAndDomainListEditor
                    emailsAndDomainsList={emailList}
                    emptyFieldWarning={false}
                    onEmailListChange={onEmailListChange}
                    placeholder="Add email addresses or domains"
                  />
                </div>
              )}
            </div>
            {isGroup && (
              <div className="link-privacy-selector__edit-group">
                <Button
                  className="link-privacy-selector__edit-group-button"
                  type="utility"
                  onClick={() => onEditGroupClick(options[index])}
                >
                  <Icon name="pencil" />
                  <span>Edit group</span>
                </Button>
              </div>
            )}
            {isGroup && deleteCallback && (
              <div className="link-privacy-selector__delete-group">
                <Button
                  className="link-privacy-selector__delete-group-button"
                  type="utility"
                  onClick={() => onDeleteGroupClick(options[index])}
                >
                  <Icon name="trash" />
                  <span>Delete group</span>
                </Button>
              </div>
            )}
          </SelectionBlock>
          <ChatPrivacyWarning
            index={index}
            isSelected={isSelected(value)}
            parentPage={parentPage}
            showDisableChatWarning={showDisableChatWarning}
            onClick={onDisableChatClick}
          />
        </Fragment>
      ))}
      <Modal
        className="delete-modal"
        title="Are you sure?"
        size={'small'}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <p className="delete-text">You will not be able to undo this action.</p>
        <Footer>
          <Button
            className="delete-button"
            type="secondary"
            onClick={() => deleteOption(optionToDelete)}
          >
            Delete Group
          </Button>
          <Button type="tertiary" onClick={() => setIsDeleteModalOpen(false)}>
            Cancel
          </Button>
        </Footer>
      </Modal>
    </div>
  );
}

LinkPrivacySelector.propTypes = {
  className: PropTypes.string,
  parentPage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDisableChatClick: PropTypes.func,
  onEmailListChange: PropTypes.func,
  onEditGroupClick: PropTypes.func.isRequired,
  deleteCallback: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.node,
      emailList: PropTypes.arrayOf(PropTypes.string),
      isGroup: PropTypes.bool,
      title: PropTypes.node.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  selectedOption: PropTypes.number,
};

LinkPrivacySelector.defaultProps = {
  onEmailListChange: () => null,
};

export default LinkPrivacySelector;
