import React from 'react';

import { Button, Footer, H2, Modal, P } from '@driftt/tide-core';

import ModalPortal from 'components/modalPortal';

import './ConfirmDeleteModal.css';

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  onClickConfirm: () => void;
  onClickCancel: () => void;
  target?: string;
}

function ConfirmDeleteModal({
  isOpen,
  onClickConfirm,
  onClickCancel,
  target = 'video',
}: ConfirmDeleteModalProps) {
  return (
    <ModalPortal>
      <Modal
        className="confirm-delete-modal"
        size="small"
        open={isOpen}
        onClose={onClickCancel}
        type="warning">
        <H2 className="header">{`Delete ${target}`}</H2>
        <P>{`Are you sure? This ${target} and all its data will be gone forever.`}</P>
        <Footer>
          <Button type="secondary" onClick={onClickConfirm}>
            {`Delete ${target}`}
          </Button>
          <Button type="tertiary" onClick={onClickCancel}>
            Cancel
          </Button>
        </Footer>
      </Modal>
    </ModalPortal>
  );
}

export default ConfirmDeleteModal;
